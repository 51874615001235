<template>
  <div>
    <v-form ref="form" v-model="formValid" @submit.prevent="getBankList()">
      <v-row class="ma-0 pa-4 align-center">
        <v-col cols="12" sm="12" md="5" lg="2" class="pa-0">
          <div class="pb-2 fsize14">User Id</div>
          <v-text-field dense outlined v-model="userId" @input="userId ? userId = userId.toUpperCase() : ''" placeholder="Enter User Id" :rules="userIdRules"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="7" class="pa-0">
          <div  class="px-5">
            <v-btn :loading="loading" height="40" depressed type="submit" color="primary" class="fsize12 text-capitalize">Submit</v-btn>
          </div>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-form>
    <!-- <v-data-table :search="search" v-if="bankList.length > 0" :headers="headers" v-bind:items="this.bankList" :value="this.bankList" class="my-5" id="table" item-key="name" single-select>
    </v-data-table> -->

    <v-simple-table id="tableData" v-if="bankList.length > 0" class="my-5">
      <thead>
        <tr class="tableRow">
          <th class="fsize12 font-weight-normal tableContent no-wrap">User Name</th>
          <th class="text-right fsize12 font-weight-normal tableContent no-wrap">Ac Type</th>
          <th class="text-center fsize12 font-weight-normal tableContent no-wrap">Bank Name</th>
          <th class="fsize12 font-weight-normal tableContent no-wrap">Acc No.</th>
          <th class="fsize12 font-weight-normal tableContent no-wrap">IFSC Code</th>
          <th class="text-center fsize12 font-weight-normal tableContent no-wrap">Bank Address</th>
          <th class="text-center fsize12 font-weight-normal tableContent no-wrap">Branch Code</th>
          <th class="text-center fsize12 font-weight-normal tableContent no-wrap">Default Bank</th>
        </tr>
      </thead>
      <tbody>
        <tr class="tableRow" v-for="(i, idex) in bankList" :key="idex">
          <td class="tableContent no-wrap">{{ i.userName }}</td>
          <td class="text-right tableContent">{{ i.accType }}</td>
          <td class="text-center tableContent">{{ i.bankName }}</td>
          <td class="tableContent">{{ i.accNo }}</td>
          <td class="tableContent">{{ i.ifscCode }}</td>
          <td class="tableContent">{{ i.bankAddress }}</td>
          <td class="text-center tableContent">{{ i.branchCode }}</td>
          <td class="text-center tableContent">{{ i.defbank }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-else>
      <div class="text-center pa-5">
        <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
        <div class="">No Data Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import commonFunc from "../../mixins/commonFunctions";
export default {
  name: "PostionAvgList",
  mixins: [commonFunc],
  data: () => ({
    userId: '',
    headers: [
      {
        text: "User Name",
        align: "left",
        sortable: false,
        value: "userName",
      },
      {
        text: "Acc Type",
        align: "center",
        sortable: false,
        value: "accType",
      },
      {
        text: "Bank Name",
        align: "center",
        sortable: false,
        value: "bankName",
      },
      {
        text: "Acc No.",
        align: "right",
        sortable: false,
        value: "accNo",
      },
      {
        text: "IFSC Code",
        align: "center",
        sortable: false,
        value: "ifscCode",
      },
      {
        text: "Bank Address",
        align: "center",
        sortable: false,
        value: "bankAddress",
      },
      {
        text: "Branch Code",
        align: "center",
        sortable: false,
        value: "branchCode",
      },
      {
        text: "Default Bank",
        align: "center",
        sortable: false,
        value: "defbank",
      },
    ],
    formValid: true,
    userIdRules: [
      (v) => !!v || "User Id is required",
      (v) =>
        (v && v.length >= 2) || "User Id must be greater than 2 characters",
    ],
  }),
  computed: {
    ...mapGetters({
      loading: "getLoader",
      search: "getSearch",
    }),
    ...mapGetters("uploadFile", { bankList: "getBankList" }),
  },
  methods: {
    getBankList() {
      if (this.$refs.form.validate()) {
        let jsonObj = {
          userId: this.userId,
        };
        this.$store.dispatch("uploadFile/getBankList", jsonObj);
      }
    },
  },
  mounted(){
    this.$refs.form.reset()
    this.$store.commit('uploadFile/setBankList' , [])
  }
};
</script>

